import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Work from "./components/Work";
// import { div } from "framer-motion/m";


// document.addEventListener('wheel', (event) => {
//   event.preventDefault();
//   const scrollSpeed = 1; // Adjust for faster or slower scrolling
//   const scrollAmount = -event.deltaY / scrollSpeed;

//   let currentScroll = window.scrollY;
//   const targetScroll = currentScroll + scrollAmount;

//   const smoothScroll = () => {
//     currentScroll += (targetScroll - currentScroll) * 0.1; // Smooth deceleration
//     window.scrollTo(0, currentScroll);

//     if (Math.abs(targetScroll - currentScroll) > 0.5) {
//       requestAnimationFrame(smoothScroll);
//     }
//   };

//   smoothScroll();
// }, { passive: false });

function App() {
  const [circle, setCircle] = useState(0); // State for showing/hiding custom cursor

  // Function to toggle cursor visibility
  const showCircle = (show) => {
    setCircle(show);
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (circle) {
        document.documentElement.style.setProperty('--x', `${e.clientX + window.scrollX}px`);
        document.documentElement.style.setProperty('--y', `${e.clientY + window.scrollY}px`);
      }
    };

    const handleScroll = () => {
      if (circle) {
        console.log("handle scrolling")
      }
    };

    if (circle) {
      document.body.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('scroll', handleScroll);
    } else {
      document.body.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [circle]);


  useEffect(() => {

    // After the timeout, add the 'cursor-visible' class to the element with id 'circularcursor'
    const cursorElement = document.getElementById("circularcursor");
    if (cursorElement) {
      if (circle) {
        cursorElement.classList.add("cursor-visible");
      } else {
        cursorElement.classList.remove("cursor-visible");
      }
    }

    // Cleanup the timer when the component unmounts or `circle` changes


  }, [circle]);  // Runs every time `circle` changes





  return (

    <Router>
      <div id="circularcursor" className="cursor-hidden flex justify-center items-center font-medium text-lg">{circle == 2 ? ("show") : ""}</div>
      <Routes>
        <Route path="/" element={<Main showCircle={showCircle} />} />
        <Route path="/work" element={<Work showCircle={showCircle} />} />
      </Routes>
    </Router>

  );
}

export default App;
