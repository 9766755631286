import { div } from "framer-motion/client";
import React from "react";
import { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";
import { HiArrowDownRight } from "react-icons/hi2";
import {
  motion
} from "framer-motion"


const text = ['A', 'A', 'K', 'A', 'S', 'H', ' ', 'P', 'A', 'T', 'E', 'L']

const allProjects = {
  1: {
    name: "Nook - Stock Broker",
    sourceCode: "https://github.com/aakashahpl/stock-broker",
    demoLink: "https://stock-broker-tau.vercel.app/",
    imageSrc: "/portfolio/stockBroker.png",
    about: {
      line1: " - Web application that allows users to access stock data, execute buy/sell transactions, and receive real-time stock price updates and business news.",
      line2: ""
    }
  },
  2: {
    name: "Blog Website",
    sourceCode: "https://github.com/aakashahpl/ang-blogWebsite",
    demoLink: "https://blog-app-blue-xi.vercel.app/",
    imageSrc: "/portfolio/blog-website.png",
    about: {
      line1: " - Developed server side of the application focusing on user management, blogs and comment handling ",
      line2: " - Utilized PassportJs, JWT for secure user management, and Multer for handling file uploads (multipart/form-data)."
    }
  },
  3: {
    name: "India Tourism",
    sourceCode: "https://github.com/aakashahpl/indiaTourism",
    demoLink: "",
    imageSrc: "/portfolio/tourism.jpg",
    about: {
      line1: "A tourism webiste which creates personalized travel itineraries based on user preferences.",
      line2: ""
    }
  },
  4: {
    name: "Skill Forge",
    sourceCode: "https://github.com/aakashahpl/Skill-forge",
    demoLink: "",
    imageSrc: "/portfolio/skillForge.jpg",
    about: {
      line1: " - Educational web app that allows users to generate personalized programming courses using Google’s Gemini API.",
      line2: " - Integrated course creation features based on custom prompts for any programming concept or language."
    }
  },
}

const Home = ({showCircle}) => {
  const [greeting, setGreeting] = useState('');
  const [currProject, setCurrProject] = useState(0);
  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 17) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  }, []);
  return (
    <div
      name="home"
      className="h-screen w-full bg-[#1a1a1a] z-10"
     
    >

      <div className="flex flex-col items-center justify-center h-full w-full lg:max-w-[80rem] mx-auto">

        {currProject == 0 ? (
          <div className="h-auto flex flex-col justify-center items-center w-full overflow-hidden">
            <div className=" flex flex-row home-bg-text w-full items-center justify-center gap-[1px]">
              {/* framer motion conflicts with css animation here  */}
              {
                text.map((letter, index) => (
                  <h2
                    key={index}
                    className={`min-w-[8px] lg:min-w-[2rem] `}
                  >
                    {letter}
                  </h2>
                ))
              }
            </div>

            <div className="text-neutral-300 flex justify-end w-full font-mulish">
              <motion.div className="">
                <h3 className="text-md md:text-2xl flex items-center gap-2">
                  <HiArrowDownRight /> FULL STACK DEVELOPER
                </h3>
                <h3 className="text-md md:text-2xl ml-8">BASED IN INDIA</h3>
              </motion.div>
            </div>
          </div>

        ) : (
          <div className=" max-w-[70rem] min-h-[30rem] flex flex-row gap-10 z-10 lg:px-10 rounded-lg py-16 relative">
            <div className={`h-3 absolute left-0 top-0 rounded-sm ${currProject == 0 ? 'animate-width-cycle bg-white' : 'animate-resize bg-gray-100 bg-opacity-70 rounded-xl'}`}></div>
            <motion.div className="flex flex-col flex-1 gap-10" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1.2 }} viewport={{ amount: 0.5 }}>
              <a href={allProjects[currProject].demoLink}>
                <img
                  src={allProjects[currProject].imageSrc}
                  alt=""
                  className="rounded-md duration-200 hover:scale-105"
                />
              </a>
              <div className=" flex-1 flex flex-row justify-around items-center text-gray-800 font-semibold">
                <a href={allProjects[currProject].sourceCode} className=" flex items-center justify-center bg-gray-400 bg-opacity-30 rounded-3xl px-4 py-2">Src code</a>
                <a href={allProjects[currProject].demoLink} className=" flex items-center justify-center bg-gray-400 bg-opacity-30 rounded-3xl px-4 py-2">Demo</a>
              </div>
            </motion.div>
            <div className=" flex flex-1 flex-col gap-2 text-stone-800">
              <motion.div className="text-3xl font-bold" initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 2, type: 'spring', delay: 0.5 }}>
                {allProjects[currProject].name}
              </motion.div>
              <motion.div className="" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1.2 }} viewport={{ amount: 0.5 }}>
                {allProjects[currProject].about.line1}
              </motion.div>
              <h3 className="" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.3, duration: 1.2 }} viewport={{ amount: 0.5 }}>{allProjects[currProject].about.line2}</h3>
            </div>
          </div>
        )}

        <div className=" w-full flex flex-row justify-between px-14 mt-6 ">
          <div className="flex flex-row gap-4">

            <div
              smooth
              className="hidden group w-fit px-6 py-3 my-2 md:flex items-center rounded-md bg-stone-200 text-black cursor-pointer"
              onClick={() => setCurrProject(1)}
            >
              Projects
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </div>



            <div

              className="group w-fit px-6 py-3 my-2 flex flex-row items-center rounded-md bg-stone-200 text-black cursor-pointer"
              rel="noreferrer"
            >
              {currProject == 0 ? (
                <a href="https://drive.google.com/file/d/1sNKnR6zxvH9GYJ6PDAA8q5wdYntu4Yvw/view?usp=sharing">
                  Resume
                </a>

              ) : (
                <>
                  <span className="flex flex-row justify-between w-16">
                    <motion.div initial={{ x: -30, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 3, type: 'spring', delay: 0.5 }}>
                      <MdOutlineKeyboardArrowLeft size={25} onClick={() => setCurrProject((currProject - 1) % 5)} />
                    </motion.div>
                    <motion.div initial={{ x: 30, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 3, type: 'spring', delay: 0.5 }}>
                      <MdOutlineKeyboardArrowRight size={25} onClick={() => setCurrProject((currProject + 1) % 5)} />
                    </motion.div>
                  </span>
                </>
              )}
            </div>
          </div>



        </div>

        <div>
        </div>


      </div>

    </div>
  );
};

export default Home;
