import React, { useState,useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as Link1 } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

const NavBar = ({showCircle}) => {
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 1,
      link: "work",
      routerLink : "/work"
    },
    {
      id: 3,
      link: "experience",
    },
    {
      id: 4,
      link: "contact",
    },
  ];


  // make scroll invisible when user scrolls down
  const [prevScroll, setPrevScroll] = useState(0);
  const [showNav,setShowNav] = useState(true);

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    if(currentScroll > prevScroll){
      setShowNav(false);
    }else{
      setShowNav(true);
    }
    setPrevScroll(currentScroll);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScroll]);


  return (
    <div className={`flex justify-between items-center hover-target fixed w-full z-100 top-0 h-24 pl-2 md:pl-20 text-gray-300 bg-[#1a1a1a] transition-transform duration-300 ease-in-out ${showNav?"showNav":"hideNav"}`}
    onMouseEnter={() => showCircle(true)} 
    onMouseLeave={() => showCircle(false)}  
    >
     
      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-row gap-2">
          <svg
            width="120px"
            height="120px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="107.33433837890627 -68.79999999999998 285.33132324218747 287.59999999999997"
            preserveAspectRatio="xMidYMid"      
            className="hoverable_svg"
          >
            <defs>
              <filter id="editing-jagged" x="-100%" y="-100%" width="300%" height="300%">
                <feTurbulence result="turb" baseFrequency="0" numOctaves="8" seed="4"></feTurbulence>
                <feDisplacementMap in="SourceGraphic" in2="turb" scale="10.5"></feDisplacementMap>
              </filter>
            </defs>
            <g filter="url(#editing-jagged)">
              <g transform="translate(154.35500979423523, 138.79000282287598)">
                <path
                  id="hover-stroke"
                  d="M21.29 0L21.29-5.46L88.27-5.46L49.69-111.57L9.10 0L3.28 0L49.69-127.58L96.10 0L21.29 0ZM172.72-66.98L162.53-61.15L159.80-65.88L169.99-71.71L169.99-71.71Q182.55-78.62 182.55-93.73L182.55-93.73L182.55-93.73Q182.55-121.94 139.59-121.94L139.59-121.94L122.85-121.94L122.85 0L117.39 0L117.39-127.40L139.59-127.40L139.59-127.40Q163.62-127.40 175.81-118.57L175.81-118.57L175.81-118.57Q188.01-109.75 188.01-93.73L188.01-93.73L188.01-93.73Q188.01-75.53 172.72-66.98L172.72-66.98Z"
                  stroke="white"       // Set the stroke color (line color)
                  strokeWidth="5"      // Set the stroke width (line thickness)
                  fill="white"
                />
              </g>
            </g>
          </svg>
          {/* <img src="/logo.jpeg" alt="logo" width={120} className="mt-10"/> */}

        </div>
      </div>
      <div className=" h-full flex items-center justify-center">
        <div className=" w-20 h-full bg-stone-200 -m-[1px]">
          <div className="h-full w-full bg-[#1a1a1a] rounded-tr-[62px]">
          </div>
        </div>
        <div className=" h-full flex justify-end items-center px-20 bg-stone-200 rounded-bl-[63px] ml-[-3px]">
          <ul className="hidden md:flex gap-4 font-mulish tracking-wider">
            {links.map(({ id, link,routerLink}) => (
              <li
                key={id}
                className="px-6 cursor-pointer uppercase text-black hover:scale-105 text-lg duration-200 "
              >
                <Link1 to={link} smooth duration={500}>
                  <Link2 to={routerLink}>

                  {link}
                  </Link2>
                </Link1>
              </li>
            ))}
          </ul>
          <div
            onClick={() => setNav(!nav)}
            className="cursor-pointer pr-4 text-gray-500 md:hidden"
          >
            {nav ? <FaTimes size={30}  />: <FaBars size={30} /> }
          </div>

          {nav && (
            <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-white">
              {links.map(({ id, link }) => (
                <li
                  key={id}
                  className="px-4 cursor-pointer capitalize py-6 text-4xl"
                >
                  <Link1
                    onClick={() => setNav(!nav)}
                    to={link}
                    smooth
                    duration={500}
                  >
                    {link}
                  </Link1>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>


    </div>
  );
};

export default NavBar;
